import * as log from 'loglevel';
import urlParsed from 'portal-utils/url';
import * as allEnvConfigs from '../config/env';

/**
 * Reads url, decides the env config to pick
 *
 * @returns {object}        The env specific config object
 */
const envConfig = () => {
  const hostname = urlParsed.hostname ? urlParsed.hostname : null;
  const pathName = urlParsed.pathname ? urlParsed.pathname : null;

  const isBeta = !!(
    pathName && pathName.indexOf(allEnvConfigs.PATH_K8_BETA) > -1
  );

  const isBeta1 = !!(
    pathName && pathName.indexOf(allEnvConfigs.PATH_K8_BETA1) > -1
  );
  const isBeta2 = !!(
    pathName && pathName.indexOf(allEnvConfigs.PATH_K8_BETA2) > -1
  );
  const isBeta3 = !!(
    pathName && pathName.indexOf(allEnvConfigs.PATH_K8_BETA3) > -1
  );
  const isBeta4 = !!(
    pathName && pathName.indexOf(allEnvConfigs.PATH_K8_BETA4) > -1
  );

  const getStagingProperties = () => {
    let envData = allEnvConfigs.ENV_STAGING_K8;
    if (isBeta) envData = allEnvConfigs.ENV_STAGING_BETA_K8;
    else if (isBeta1) envData = allEnvConfigs.ENV_STAGING_BETA1_K8;
    else if (isBeta2) envData = allEnvConfigs.ENV_STAGING_BETA2_K8;
    else if (isBeta3) envData = allEnvConfigs.ENV_STAGING_BETA3_K8;
    else if (isBeta4) envData = allEnvConfigs.ENV_STAGING_BETA4_K8;
    return envData;
  };

  const getProdUsProperties = () => {
    let envData = allEnvConfigs.ENV_PRODUCTION_R2;
    if (isBeta) envData = allEnvConfigs.ENV_PRODUCTION_BETA_K8;
    else if (isBeta1) envData = allEnvConfigs.ENV_PRODUCTION_BETA1_K8;
    else if (isBeta2) envData = allEnvConfigs.ENV_PRODUCTION_BETA2_K8;
    else if (isBeta3) envData = allEnvConfigs.ENV_PRODUCTION_BETA3_K8;
    else if (isBeta4) envData = allEnvConfigs.ENV_PRODUCTION_BETA4_K8;
    return envData;
  };

  let env = '';
  switch (hostname) {
    case allEnvConfigs.DEV_UI_URL:
      env = allEnvConfigs.ENV_DEV_UI;

      break;
    case allEnvConfigs.DEV_K8_URL:
      env = isBeta ? allEnvConfigs.ENV_DEV_K8_BETA : allEnvConfigs.ENV_DEV_K8;

      break;
    case allEnvConfigs.PRODUCTION_US_URL:
      env = getProdUsProperties();

      break;
    case allEnvConfigs.STAGING_K8_URL:
      env = getStagingProperties();

      break;
    case allEnvConfigs.PRODUCTION_EU_URL:
      env = isBeta
        ? allEnvConfigs.ENV_PRODUCTION_EU_BETA_K8
        : allEnvConfigs.ENV_PRODUCTION_R1;

      break;
    default:
      env = allEnvConfigs.ENV_DEV_UI;

      break;
  }

  log.info(`Selected environment: ${env}`);

  return {
    ...allEnvConfigs.config[env],
    envName: env,
    EULA_LINK: allEnvConfigs.EULA_LINK,
  };
};

export default envConfig();
