import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import es from './es/translation.json';
import zh from './zh/translation.json';
import fr from './fr/translation.json';
import de from './de/translation.json';
import ar from './ar/translation.json';
import nl from './nl/translation.json';
import tr from './tr/translation.json';
import it from './it/translation.json';

// export const DEFAULT_LOCALE = { code: 'en', label: 'English' };

// export const appLocales = [
//   { code: 'en', label: 'English' },
//   { code: 'es', label: 'Español' },
//   { code: 'zh', label: '中文' },
// ];

const translationsJson = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  zh: {
    translation: zh,
  },
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
  ar: {
    translation: ar,
  },
  nl: {
    translation: nl,
  },
  tr: {
    translation: tr,
  },
  it: {
    translation: it,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
