import { msalAuthRedirect } from 'portal-utils/AzureConnect';

import config from '../utils/getEnv';
import { updateAxiosInstance } from '../utils/axiosInstance';

// eslint-disable-next-line import/no-mutable-exports
export let impersonatedUserName = null;

const auth = msalAuthRedirect.auth(config);
export const {
  logout,
  getUser,
  isValidToken,
  impersonateRedirect,
  getUserId,
  getUserEmail,
  getUserIdp,
  getLastLogonTime,
  getImpersonatedDisplayName,
  getLoggedUserEmailId,
  getUserLastName,
} = auth;

export const loginUser = () =>
  auth.getToken().then(token => {
    // change axios token here
    updateAxiosInstance(token, auth.getToken, isValidToken);
    impersonatedUserName = auth.getImpersonatedDisplayName();
    return token;
  });

// In case of impersonatePopup change the token for axios here
// For impersonateRedirect, it will be same as acquiretokenredirect flow
// export const impersonatePopup = () =>
//   auth.impersonateUser().then((res) => {
//     impersonatedUserName = res.impersonatedUser;
//     // change axios token here
//     updateAxiosInstance(res.token, logout, isValidToken);
//     console.log("token", res.token);
//     return res;
//   });
