/**
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/symbol';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import * as log from 'loglevel';
import { last } from 'lodash';
import setupLogger from 'portal-utils/logger';

import FontFaceObserver from 'fontfaceobserver';
import { HelmetProvider } from 'react-helmet-async';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import {
  loginUser,
  impersonateRedirect,
  getUserEmail,
  getUserIdp,
  getImpersonatedDisplayName,
} from './auth';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import './index.scss';
// Initialize languages
import './locales/i18n';
import env from './utils/getEnv';
import { RemoteConfigContext } from './remoteConfigContext';

// setup Logger
setupLogger();

// Observe loading of Roboto
const font = new FontFaceObserver('Roboto', {});

// When Roboto is loaded, add a font-family using Roboto to the body
font.load().then(() => {
  log.info('Roboto loaded');
  document.body.classList.add('fontLoaded');
});

const App = lazy(() => import('./views/containers/App'));

// Keeping the fallback empty, so that the css loader will be shown
const ConnectedApp = ({ store }) => {
  let shouldUseApplicationInsights = false;
  let applicationInsights;
  if (env.applicationInsights) {
    applicationInsights = import('./ApplicationInsights');
    shouldUseApplicationInsights = true;
  }
  return (
    <Provider store={store}>
      <HelmetProvider>
        <RemoteConfigContext.Provider>
          <React.StrictMode>
            <Suspense fallback={<></>}>
              {shouldUseApplicationInsights ? (
                <AppInsightsContext.Provider
                  value={applicationInsights.reactPlugin}
                >
                  <App />
                </AppInsightsContext.Provider>
              ) : (
                <App />
              )}
            </Suspense>
          </React.StrictMode>
        </RemoteConfigContext.Provider>
      </HelmetProvider>
    </Provider>
  );
};

ConnectedApp.propTypes = {
  store: PropTypes.object,
};

const render = (component, mountNode) => {
  const initialState = {};
  const store = configureStore(initialState);
  ReactDOM.render(
    <ConnectedApp component={component} store={store} />,
    mountNode,
  );
};

// eslint-disable-next-line
const getUserDomain = () => {
  const userEmail = getUserEmail();
  return (userEmail && last(userEmail.split('@'))) || getUserIdp();
};

/**
 * When user is redirected to redirectUri after impersonate flow,
 * msal navigateToLoginRequestUrl will again redirect to /impersonate
 * Can't determine which flow to follow as msal is initializes at redirectUri, not at /impersonate
 * So checking here using getImpersonatedDisplayName and using redirect in react-router
 * @param {*} pathname
 * @returns bool
 */
const isImpersonateFlow = pathname =>
  pathname &&
  pathname.indexOf('impersonate') > -1 &&
  !getImpersonatedDisplayName();

/**
 * Convert to new URL format
 * @param {*} oldURL
 * @returns string
 */
const makeNewPAIURL = oldURL => {
  const decodedURL = decodeURIComponent(oldURL);
  const isEquipmentPage = decodedURL.includes('/equipment-details/pai');
  const url = new URL(decodedURL);
  const parts = url.pathname.split('/');
  const newParts = parts.map(part => part.split('_=_')[0]);
  if (isEquipmentPage) {
    const spliceIndex = newParts.length - 6;
    newParts.splice(spliceIndex, 1);
  } else if (newParts[newParts.length - 1] === 'list') {
    newParts[newParts.length - 1] = 'active';
  }
  url.pathname = newParts.join('/');
  return url.href;
};

// Get pathname
const urlObj = new URL(window.location.href);
const pathname = urlObj.pathname ? urlObj.pathname : null;

// Replace to new url, if old bookmark is used
if (/_=_(.*)?_=_/.test(decodeURIComponent(pathname))) {
  const newURL = makeNewPAIURL(window.location.href);
  window.location.replace(newURL);
}
// Go to impersonate flow based on url
else if (isImpersonateFlow(pathname)) {
  log.info('ImpersonateFlow');
  impersonateRedirect();
} else {
  loginUser()
    // eslint-disable-next-line no-unused-vars
    .then(async token => {
      const MOUNT_NODE = document.querySelector('#root');

      /* ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </React.StrictMode>,
      document.getElementById('root'),
    ); */

      render(App, MOUNT_NODE);

      /* if (module.hot) {
      // Hot reloadable translation json files and app
      // modules.hot.accept does not accept dynamic dependencies,
      // have to be constants at compile-time
      module.hot.accept(['./views', './locales/i18n'], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        const App = require('./app').App;
        render(App);
      });
    } */
    })
    .catch(e => {
      log.error(e);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
