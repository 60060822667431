/**
 * Axios instance to be used in this App
 */

import axios from 'axios';
import * as log from 'loglevel';
import envConfig from './getEnv';

/**
 * Creates an axios instance
 *
 * @returns {object}        axios instance
 */
/**
 * Axios instance to be used in this App
 */

const timeout = 600000;
const { baseUrl } = envConfig;
const maxContentLength = 31457280;

const instance = axios.create({
  timeout,
  baseUrl,
  maxContentLength,
  headers: { 'Accept-Language': 'en' },
});

/**
 * Change the language in request header
 * @param {string} languageCode - Language code (en | es | zh)
 */
export const updateAxiosInstanceLanguage = languageCode => {
  instance.defaults.headers['Accept-Language'] = languageCode;
};

export const updateAxiosInstanceResponseType = () => {
  instance.defaults.responseType = 'arraybuffer';
};

export const resetAxiosInstanceResponseType = () => {
  instance.defaults.responseType = '';
};

export const updateAxiosTimeout = axiosTimeout => {
  instance.defaults.timeout = axiosTimeout;
};

export const updateAxiosInstanceWithErrorCode = (
  translationFailed,
  unsupportedLanguage,
) => {
  instance.interceptors.response.use(
    response =>
      // if (response.status === 206) {
      //   translationFailed(true);
      // }
      response,
    error => {
      if (error.response.status === 406) {
        unsupportedLanguage(true);
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('');
      }
      return Promise.reject(error);
    },
  );
};

/**
 * Updates the axios instance token
 * @param {string} token - access-token
 * @param {function} login - The login funtion
 * @param {binary} isValidToken - True if token is not expired
 */
export const updateAxiosInstance = (token, login, isValidToken) => {
  instance.interceptors.request.use(
    config => {
      let tokenForHeader = token;
      if (isValidToken()) {
        /* eslint-disable no-param-reassign */
        config.headers.Authorization = `Bearer ${tokenForHeader}`;
        // config.headers["Accept-Language"] = "en";
        return config;
      }
      log.info('Token expired, trying to renew');
      // return a promise that resolves to a token
      // If silentToken is success, axios will continue with request or will be redirected to login
      // If redirected to login, Cannot read property 'cancelToken' of undefined error will come
      return login()
        .then(newToken => {
          tokenForHeader = newToken;
          config.headers.Authorization = `Bearer ${newToken}`;
          return config;
        })
        .catch(e => {
          log.error(e);
        });
      /* eslint-enable no-param-reassign */
    },
    error => Promise.reject(error),
  );
};

export default instance;
